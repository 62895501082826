// DiningOut.jsx
import React, { useState, useEffect, useRef} from "react";
import {
    Button, Typography,
} from "@mui/material";
import {printOrder , getIncomeAPI} from "../../../utils/ordersControlar.js";
import {useGlobalOrder} from "../../../utils/GlobalStateContext";

function SettingTab() {

    const { systemData, setSystemData, setGlobalOrder } = useGlobalOrder();

    return (
        <>
            設定<br/>
            聲音播放：<br/>
            Websocket狀態：<br/>
            <Typography
                sx={{
                    fontSize: "20px",
                    lineHeight: "24.2px",
                    textAlign: "center",
                    fontWeight: "400",
                    letterSpacing: 4,
                }}
                align="center"
            >
                目前位置：{systemData.name}
            </Typography>
        </>
    ) ;
}

export default SettingTab;
