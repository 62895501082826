import axios from "axios";
import { toast } from "react-toastify";

const API_URL = process.env.NODE_ENV === "production" ? "https://www.ciaowin35.com/api/pos" : "http://debug.ciaowin35.com/api/pos" ; // https://apidebug.ciaowin35.com/api/pos

// axios 实例封装
const api_client = axios.create({
    baseURL: API_URL,
    timeout: 15000,
});

// JWT Token 中间件
api_client.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token"); // 从 localStorage 获取 JWT token
        if (token) {
            config.headers.Authorization = `Bearer ${token}`; // 添加 JWT token 到 headers
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 统一错误处理
const handleError = (error) => {
    if (error.response) {
        toast.error(error.response.data.message || "伺服器錯誤");
    } else {
        toast.error("網絡錯誤");
    }
    throw error;
};

api_client.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Token 過期，進行處理
            localStorage.removeItem("jwtToken");
            localStorage.removeItem("token");
            toast.error("登入過期，請重新登入");
            window.location.href = ("/");
        }
        return Promise.reject(error);
    }
);

export default api_client;
