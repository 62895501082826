import React, {useEffect, useState} from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGlobalOrder } from "../../utils/GlobalStateContext"; // 全局狀態
import { loginUser, logout } from "../../reducers/authSlice";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";

function SystemLoginPage({ setSystemLogin , setSystemData , checkAuthentication }) {
  const dispatch = useDispatch();
  const [account, setAccount] = useState(""); // 使用者帳號
  const [password, setPassword] = useState(""); // 使用者密碼
  const [code, setCode] = useState(""); // 綁定碼
  const [inputCode, setInputCode] = useState(0); //0不輸入 1請輸入
  const [error, setError] = useState(""); // 錯誤訊息
  const [loading, setLoading] = useState(false); // 載入狀態
  const { toast , playBeep } = useGlobalOrder(); // 全局 toast 提示
  const navigate = useNavigate(); // 用來導航至其他頁面

    useEffect(() => {
        if(localStorage.getItem("account") && !account){
            setAccount(localStorage.getItem("account")) ;
        }
    }, []);

  // 登入函數
  const systemLogin = async (event) => {

    event.preventDefault(); // 防止表單預設提交
    setLoading(true);
    setError(""); // 清除錯誤訊息

    dispatch(loginUser({account, password, code})).unwrap().then((response)=>{
        console.log(response);
        if (response.error===0) {
            if(response.show_code === 1){
                setInputCode(1) ;
                alert(response.msg);
            }else{
                checkAuthentication().then(() => {
                    navigate("/"); // 登入成功後導向首頁
                    toast.success("已成功登入");
                    playBeep();
                });
            }
        } else {
            toast.error(response.msg || "登入失敗，請檢查帳號和密碼");
            setError(response.msg || "登入失敗，請檢查帳號和密碼");
        }
        setLoading(false);
    });
  };

    return (
      <section
          id="system_login_page"
          className="bg-light_gray h-[100vh] w-[100%] flex justify-center items-center"
      >
        <div
            id="login_form"
            className="bg-dark_gray rounded-3xl p-[48px] grid gap-[32px] justify-center items-center"
        >
          <h1 className="system_login_title">POS</h1>

          <form onSubmit={systemLogin} className="flex flex-col items-center w-full">
            {/* 帳號輸入框 */}
            <div className="flex w-full items-center justify-center mb-3">
              <label className="system_login_text min-w-[96px]" htmlFor="account">帳號</label>
              <input
                  id="account"
                  type="text"
                  className="system_login_text text-[#000] text-[24px] w-[320px] p-2 rounded-full border border-gray-300"
                  value={account}
                  onChange={(e) => setAccount(e.target.value)} // 更新帳號狀態
                  required
              />
            </div>

            {/* 密碼輸入框 */}
            <div className="flex w-full items-center justify-center mb-3">
              <label className="system_login_text min-w-[96px]" htmlFor="password">密碼</label>
              <input
                  id="password"
                  type="password"
                  className="system_login_text text-[#000] text-[24px] w-[320px] p-2 rounded-full border border-gray-300"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} // 更新密碼狀態
                  required
              />
            </div>

              {/* 密碼輸入框 */}
              {(localStorage.getItem("auth_code") || inputCode === 0) ? "" : (
                  <div className="flex w-full items-center justify-center mb-3">
                      <label className="system_login_text min-w-[96px]" htmlFor="code">驗證碼</label>
                      <input
                          id="code"
                          type="password"
                          className="system_login_text text-[#000] text-[24px] w-[320px] p-2 rounded-full border border-gray-300"
                          value={code}
                          onChange={(e) => setCode(e.target.value)} // 更新密碼狀態
                          required
                      />
                  </div>
              )}

              {/* 錯誤訊息顯示 */}
              {error && (
                  <Typography color="error" variant="h6">
                      {error}
                  </Typography>
              )}

              {/* 登入按鈕 */}
              <Button
                  type="submit"
                  disabled={loading} // 當載入中時禁用按鈕
                  sx={{
                      backgroundColor: "#e6e5e5",
                      width: '100%',
                      height: 80,
                      marginX: "auto",
                      marginY: "40px",
                      borderRadius: 3,
                }}
            >
              <p
                  className="text-[#A43B3B]"
                  style={{
                    fontSize: "32px",
                    lineHeight: "42.36px",
                    textAlign: "center",
                    fontWeight: "700",
                    letterSpacing: 4,
                  }}
              >
                {loading ? "登入中..." : "登入"}
              </p>
            </Button>
          </form>
        </div>
      </section>
  );
}

export default SystemLoginPage;
