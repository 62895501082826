// OrderHistory.jsx
import React, { useState, useEffect, useRef } from "react";
import {
    Grid,
    Button,
    ButtonGroup,
    TextField,
} from "@mui/material";
import { orderTypes } from "../../utils/status_table";
import { getOrders } from "../../utils/ordersControlar.js";
import OrderRow from "./OrderRow";
import OrderDetail from "./OrderDetail";
function OrderHistory() {

    // 取得目前日期
    const now = new Date();

    // 格式化為 Y-m-d
    const year = now.getFullYear(); // 取得年份
    const month = String(now.getMonth() + 1).padStart(2, '0'); // 取得月份（0-11），需要 +1 並補0
    const day = String(now.getDate()).padStart(2, '0'); // 取得日期，補0
    const formattedDate = `${year}-${month}-${day}`; // 組合成 Y-m-d 格式

    const debounceTimeout = useRef(null);
    const [filter, setFilter] = useState({
        start_time: formattedDate,
        end_time: formattedDate,
        method: "",
        search: "",
    });
    const [ordersData, setOrdersData] = useState(null);
    const [inputValue, setInputValue] = useState(""); // 用來暫存用戶輸入的值
    const [orderDetail, setOrderDetail] = useState(null);
    const [selectedOrderId, setSelectedOrderId] = useState(null);

    const fetchOrders = async () => {
        const order = await getOrders({
            start_time: filter.start_time.replace(/-/g, "/"),
            end_time: filter.end_time.replace(/-/g, "/"),
            method: filter.method,
            search: filter.search,
        });
        setOrdersData(order);
    };
    const handleStartDateChange = (e) => {
        setFilter({ ...filter, start_time: e.target.value });
    };

    const handleEndDateChange = (e) => {
        setFilter({ ...filter, end_time: e.target.value });
    };

    const handleMethodChange = (value) => {
        if (filter.method === value) {
            setFilter({ ...filter, method: "" });
        } else {
            setFilter({ ...filter, method: value });
        }
    };

    const handleSearchChange = (e) => {
        const newValue = e.target.value; // 更新輸入框的值
        setInputValue(newValue); // 更新本地狀態

        // 當輸入的值為空時清空 filter.search
        if (newValue.length === 0) {
            setFilter((prev) => ({ ...prev, search: "" }));
        }
    };

    const handleSearchSubmit = () => {
        setFilter((prev) => ({ ...prev, search: inputValue })); // 按下按鈕時更新 filter.search
    };

    useEffect(() => {
        // 清除上一個 timeout，並重新設置新的 timeout
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // 設置一個新的 timeout 來執行 fetchOrders
        debounceTimeout.current = setTimeout(() => {
            fetchOrders();
        }, 500); // 延遲 1 秒

        // 清理 function，當下一次 effect 觸發時會清除這次的 timeout
        return () => {
            clearTimeout(debounceTimeout.current);
        };
    }, [filter]);

    // 計算總金額

    const history_type = [
        { title: "單號", xs: 2 },
        { title: "訂單狀態", xs: 2 },
        { title: "訂購人", xs: 2 },
        { title: "支付方式", xs: 2 },
        { title: "金額", xs: 2 },
        { title: "印單於", xs: 2 },
    ];
    return (
        <Grid
            container
            direction="column"
            sx={{ backgroundColor: "#f0f0f0", height: "100vh" }}
        >
            {/* 上方ButtonGroup区域，用于选择订单类型 */}
            <Grid
                container
                spacing={2}
                sx={{
                    marginX: "0px !important",
                    height: 92,
                    alignItems: "center",
                    width: "100%",
                    padding: "0px 16px",
                    gap: "16px",
                    flexWrap: "nowrap",
                    overflow: "auto",
                }}
                className="hide-scrollbar"
            >
                {/* <Grid item>
          <p className="min-w-[44px] sidebar_btn_text_b text-sold_out_gray text-left">
            篩選
          </p>
        </Grid> */}
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                    {/* 开始日期选择 */}
                    <TextField
                        type="date"
                        value={filter.start_time}
                        onChange={handleStartDateChange}
                        InputProps={{
                            sx: {
                                fontSize: "16px",
                                color: "#797777",
                                backgroundColor: "#FFF",
                                height: "48px",
                                borderRadius: "9999px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                "&::placeholder": {
                                    color: "#C0C0C0", // 自定义占位符颜色
                                },
                                "&::-webkit-calendar-picker-indicator": {
                                    display: "none", // 隐藏日历图标
                                },
                            },
                        }}
                    />
                    <p className="sidebar_btn_text_b text-sold_out_gray text-left">~</p>
                    {/* 结束日期选择 */}
                    <TextField
                        type="date"
                        value={filter.end_time}
                        onChange={handleEndDateChange}
                        InputProps={{
                            sx: {
                                fontSize: "16px",
                                color: "#797777",
                                backgroundColor: "#FFF",
                                height: "48px",
                                borderRadius: "9999px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                "&::placeholder": {
                                    color: "#C0C0C0", // 自定义占位符颜色
                                },
                                "&::-webkit-calendar-picker-indicator": {
                                    display: "none", // 隐藏日历图标
                                },
                            },
                        }}
                    />
                </Grid>
                {/* 訂單類型選擇 */}
                <Grid item>
                    <ButtonGroup
                        sx={{
                            overflow: "hidden",
                            height: "48px",
                        }}
                        aria-label="Order type selection"
                    >
                        {orderTypes.map((type) => (
                            <Button
                                key={type.value}
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    px: 3,
                                    border: "1px solid #8E8E8E",
                                    borderRadius: 4,
                                    borderColor:
                                        filter.method === type.value ? "#FFF" : "#8E8E8E",
                                    backgroundColor:
                                        filter.method === type.value ? "#FFF" : "#8E8E8E",
                                    color: filter.method === type.value ? "#A43B3B" : "#FFF",
                                }}
                                onClick={() => handleMethodChange(type.value)}
                            >
                                <p className="flex min-w-[36px]">{type.label}</p>
                            </Button>
                        ))}
                    </ButtonGroup>
                </Grid>
                {/* 搜索框 */}
                <Grid
                    item
                    sx={{
                        width: "fit-content",
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <TextField
                        label="搜尋名稱/電話"
                        variant="outlined"
                        value={inputValue} // 使用 inputValue 來顯示輸入框的值
                        onChange={handleSearchChange} // 更新輸入框的值
                        InputProps={{
                            sx: {
                                minWidth: "180px",
                                fontSize: "16px",
                                color: "#797777",
                                backgroundColor: "#FFF",
                                height: "48px",
                                borderRadius: "9999px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                "&::placeholder": {
                                    color: "#C0C0C0", // 自定义占位符颜色
                                },
                                "&::-webkit-calendar-picker-indicator": {
                                    display: "none", // 隐藏日历图标
                                },
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                minWidth: "180px",
                                fontSize: "16px",
                                color: "#797777",
                                display: "flex",
                                alignItems: "center",
                                height: "34%",
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        onClick={handleSearchSubmit}
                        fullWidth
                        sx={{
                            height: "48px",
                            width: "84px",
                            display: "flex",
                            backgroundColor: "#FFF",
                            borderRadius: 7,
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        <p className="sidebar_btn_text_b text-dark_red flex">送出</p>
                    </Button>
                </Grid>
            </Grid>

            {/* 下方区域 */}
            <Grid
                item
                container
                direction="row"
                sx={{
                    height: "89%",
                    flexGrow: 1,
                    display: "flex",
                    flexWrap: "nowrap",
                    border: "none",
                    borderTop: "1px solid #797777",
                }}
            >
                <Grid
                    item
                    container
                    direction="column"
                    sx={{
                        paddingY: 2,
                        paddingX: 2,
                        border: "none",
                        borderRight: "1px solid #797777",
                        height: "100%", // 主容器的高度
                        overflow: "hidden", // 隱藏主容器滾動條
                    }}
                    className="hide-scrollbar"
                    xs={9}
                >
                    {/* 標題 */}
                    <Grid
                        container
                        direction="row"
                        sx={{
                            marginBottom: 1,
                            fontWeight: "bold",
                            display: "flex",
                            alignItems: "center",
                            border: "none",
                            borderBottom: "1px solid #797777",
                        }}
                    >
                        {history_type.map((type) => (
                            <Grid key={type.title} item xs={type.xs}>
                                <p className="sidebar_btn_text text-sold_out_gray text-left text-center justify-center items-center flex mb-[16px]">
                                    {type.title}
                                </p>
                            </Grid>
                        ))}
                    </Grid>

                    {/* 訂單 */}
                    <Grid
                        container
                        direction="column"
                        sx={{
                            flex: 1, // 填滿剩餘空間
                            overflowY: "auto", // 允許垂直滾動
                            flexWrap: "nowrap",
                            gap: "16px",
                        }}
                        className="hide-scrollbar"
                    >
                        {ordersData ? (
                            ordersData?.map((order) => (
                                <Grid
                                    key={order.order_id}
                                    container
                                    direction="row"
                                    sx={{
                                        background:
                                            selectedOrderId === order.order_id
                                                ? "rgb(67,67,67)" // 點擊後背景顏色
                                                : [0, 1, 2].includes(order.status)
                                                    ? "#797777"
                                                    : "#FFF",
                                        borderRadius: 4,
                                        fontWeight: "bold",
                                        display: "flex",
                                        alignItems: "center",
                                        border: "none",
                                        cursor: "pointer", // 增加指針效果
                                    }}
                                    onClick={() => {
                                        setOrderDetail(order);
                                        setSelectedOrderId(order.order_id); // 設置選中的訂單
                                    }}
                                >
                                    {<OrderRow order={order} key={order} />}
                                </Grid>
                            ))
                        ) : (
                            <h1 className="system_login_title text-dark_red flex items-center justify-center h-full w-full">
                                請選擇搜尋區間
                            </h1>
                        )}
                    </Grid>
                </Grid>

                {/* 右侧区域 */}
                <OrderDetail orderDetail={orderDetail} ordersData={ordersData} type={2}/>
            </Grid>
        </Grid>
    );
}

export default OrderHistory;
