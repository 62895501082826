import {useState, useEffect} from "react";
import {getEnvAPI, getOrders} from "../../../utils/ordersControlar";

const useAuthToken = (setTodayOrders, setSystemData) => {
    const [systemLogin, setSystemLogin] = useState(false);
    const [loading, setLoading] = useState(true);

    const checkAuthentication = async () => {
        if (systemLogin !== false)
            return;
        const token = localStorage.getItem("token");
        if (token) {
            const newData = await getEnvAPI();
            if (newData?.error === 0) {
                setSystemData(newData.store);
                setSystemLogin(true);
            } else {
                setSystemLogin(false);
            }
        } else {
            setSystemLogin(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        checkAuthentication();
    }, [systemLogin]);

    return {systemLogin, loading, setSystemLogin, checkAuthentication};
};

export default useAuthToken;
