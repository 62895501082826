export const statusTable = [
  {
    key: 0,
    title: "未送單",
  },
  {
    key: 1,
    title: "送單未結",
  },
  {
    key: 2,
    title: "送單已結",
  },
  {
    key: 3,
    title: "已確認",
  },
  {
    key: 4,
    title: "運送中",
  },
  {
    key: 5,
    title: "送達",
  },
  {
    key: 6,
    title: "完成",
  },
  {
    key: 7,
    title: "取消", //無法刪單改單改為重訂
  },
  {
    key: 8,
    title: "退貨",//無法刪單改單改為重訂
  },
];

export const orderTypes = [
  { label: "外送", value: 1 },
  { label: "外帶", value: 2 },
  { label: "內用", value: 3 },
  { label: "UB", value: 4 },
//  { label: "FP", value: 5 },
];
